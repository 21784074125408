import Forceplate from "@alphatekas/pwr_core/dist/forceplate";
import Leveller from "@alphatekas/pwr_core/dist/leveller";
import Keeper from "@alphatekas/pwr_core/dist/keeper";
import TIA from "@alphatekas/pwr_core/dist/cmj/tia";
import TOV from "@alphatekas/pwr_core/dist/cmj/tov";

console.log("__INIT__");

const hz = 434.0277777777777;

const forceplate = new Forceplate({
    hz,
    active_s: 1,
    active_w: 10,
});

const leveller = new Leveller({
    seconds: 2,
    variance: 1,
    w_cutoff: 5,
    deps: {
        forceplate
    },
});

const tia = new TIA({
    max_jump_time_s: 1,
    deps: {
        forceplate,
        leveller,
    },
});

const tov = new TOV({
    deps: {
        forceplate,
        leveller,
    },
});

const keeper = new Keeper({
    seconds: 2,
    deps: {
        forceplate
    },
});

export {
    forceplate,
    leveller,
    tia,
    tov,
    keeper,
};
