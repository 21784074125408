import React from "react";

enum Screen
{
    Connect = "connect",
    WeighIn = "weigh-in",
    CMJ = "cmj",
}

type ScreenContext = [
    Screen,
    (screen: Screen) => void,
];

const context = React.createContext<ScreenContext>(null);

export default context;

export {
    Screen,
};
