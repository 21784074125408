type Specs = {
    width: number;
    height: number;
};

function specs(inches: number): Specs
{
    const { sqrt, pow } = Math;

    const diagonal = inches * 2.54;

    return {
        width: diagonal * (9 / sqrt(pow(16, 2) + pow(9, 2))),
        height: diagonal * (16 / sqrt(pow(16, 2) + pow(9, 2))),
    };
}

export {
    Specs,
    specs,
};
