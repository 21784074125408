import React, { useEffect, useState } from 'react';
import TopBar from './layout/TopBar';
import WeighIn from './screens/WeighIn';
import Connect from "./screens/Connect";
import CMJ from './screens/CMJ';
import styles from "./App.less";
import ScreenContext, { Screen } from "../helpers/ScreenContext";
import match from "@korkje/match";
import Background, { Props as BgProps } from './layout/Background';
import * as AT from "../init";

type Props = {};

function App(props: Props)
{
    const [screen, setScreen] = useState<Screen>(Screen.Connect);

    const [bgProps, setBgProps] = useState<BgProps>({
        transparent: false,
        blink: undefined,
    });

    useEffect(() => {
        if (screen === Screen.CMJ)
        {
            setBgProps(ps => ({
                transparent: true,
                blink: ps.blink,
            }));
        }
        else
        {
            setBgProps(ps => ({
                transparent: false,
                blink: ps.blink,
            }));
        }
    }, [screen]);

    const blink = () => {
        setBgProps(ps => ({
            transparent: ps.transparent,
            blink: Math.random(),
        }));
    };

    useEffect(() => {
        AT.tov.on("end", ({ cancelled }) => {
            if (!cancelled)
            {
                blink();
            }
        });
    }, []);

    return (
        <ScreenContext.Provider value={[screen, setScreen]}>
            <div className={styles.AppWrapper}>
                <Background {...bgProps} />
                <div className={styles.App}>
                    <TopBar />
                    <main>
                        {
                            match(screen)
                                .on(
                                    Screen.Connect,
                                    () => <Connect />
                                )
                                .on(
                                    Screen.WeighIn,
                                    () => <WeighIn />
                                )
                                .on(
                                    Screen.CMJ,
                                    () => <CMJ />
                                )
                                .result()
                        }
                    </main>
                </div>
            </div>
        </ScreenContext.Provider>
    );
}

export default App;

export {
    App,
    Props,
};
