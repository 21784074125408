import p5 from "p5";
import * as AT from "./init";
import { Landing as TIAData } from "@alphatekas/pwr_core/dist/cmj/tia";
import { End as TOVData } from "@alphatekas/pwr_core/dist/cmj/tov";
import { specs } from "./helpers/tv";
import { brand, ux } from "./helpers/colors";

const sketch = (p: p5) => {
    let connected: boolean = false;
    let points: number[] = new Array(150).fill(0);
    let _points: number[] = new Array(5).fill(0);
    let _points_i: number = 0;
    let level: number | undefined;

    AT.forceplate.on("connection", _ => connected = _);

    AT.forceplate.on("data", ({ weight }) => {
        _points[_points_i++] = weight;
        if (_points_i === _points.length)
        {
            const avg = _points.reduce((a, b) => a + b) / _points.length;
            points.push(avg);
            points.shift();
            _points_i = 0;
        }
    });

    AT.leveller.on("level", _ => level = _);

    let tia_start: boolean = false;
    let tia_data: TIAData | undefined;
    let tia_ish: number | undefined;

    AT.tia.on("start", () => {
        console.log("TIA start:", performance.now());

        tia_start = true;
        tia_data = undefined;
        tia_ish = 0;
    });

    AT.tia.on("end", data => {
        console.log("TIA end:", data.height);

        tia_start = false;
        tia_data = data;
        console.log();
    });

    let tov_data: TOVData | undefined;

    AT.tov.on("end", data => {
        console.log("TOV:", performance.now(), data.height);

        tov_data = data;
    });

    var open_sans: {
        regular: p5.Font;
        bold: p5.Font;
    };

    p.preload = () => {
        open_sans = {
            regular: p.loadFont(require("./ux/fonts/OpenSans-Regular.ttf")),
            bold: p.loadFont(require("./ux/fonts/OpenSans-Bold.ttf")),
        };
    };

    p.setup = () => {
        p.createCanvas(p.windowWidth, p.windowHeight);

        drawOnce();
    };

    const drawOnce = () => {p.background(brand.black);

        p.fill(brand.white);
        p.noStroke();
        p.rectMode(p.CENTER);
        p.textFont(open_sans.regular);

        const cm = p.height / tv.height;
        const padding = 5 * cm;

        { // Scale
            p.textSize(22);

            const W_1 = 10;
            const W_5 = 14;
            const W_10 = 18;
            const H_1 = 2;
            const H_5_10 = 3;

            const TEXT_PAD_Y = 3;
            const TEXT_PAD_X = 10;

            const STEPS = 62;

            for (let i = 0; i <= STEPS; ++i)
            {
                p.fill(ux.green_medium);
                const y = p.height - i * cm - padding;

                let width = W_1;
                let height = H_1;

                if (i % 10 === 0)
                {
                    p.fill(brand.white);
                    width = W_10;
                    height = H_5_10;
                }
                else if (i % 5 === 0)
                {
                    p.fill(brand.white);
                    width = W_5;
                    height = H_5_10;
                }

                // Left
                p.rect(width / 2, y, width, height);

                // Right
                p.rect(p.width - width / 2, y, width, height);

                // Text
                if (i % 10 === 0)
                {
                    p.fill(brand.white);

                    // Text left
                    p.textAlign(p.LEFT, p.CENTER);
                    p.text(i.toString(), W_10 + TEXT_PAD_X, y - TEXT_PAD_Y);

                    // Text right
                    p.textAlign(p.RIGHT, p.CENTER);
                    p.text(i.toString(), p.width - (W_10 + TEXT_PAD_X), y - TEXT_PAD_Y);
                }
            }

            // TIA/TOV
            const y = p.height - STEPS * cm - padding - 75;
            p.textAlign(p.LEFT, p.BOTTOM);
            p.textSize(28);
            p.fill(brand.white);
            p.text("TOV", W_1, y);
            p.textAlign(p.LEFT, p.TOP);
            p.textSize(22);
            p.fill(brand.gold);
            p.text("Take-Off Velocity", W_1, y);

            p.textAlign(p.RIGHT, p.BOTTOM);
            p.textSize(28);
            p.fill(brand.white);
            p.text("TIA", p.width - W_1, y);
            p.textAlign(p.RIGHT, p.TOP);
            p.textSize(22);
            p.fill(brand.gold);
            p.text("Time In Air", p.width - W_1, y);
        }
    };

    const tv = specs(50);

    p.draw = () => {
        if (p.frameCount % 100 === 0)
        {
            drawOnce();
        }

        p.noStroke();
        p.rectMode(p.CENTER);
        p.textFont(open_sans.regular);
        p.fill(brand.black);

        const cm = p.height / tv.height;
        const padding = 5 * cm;

        const w_pad = 60;
        p.rect(
            p.width / 2,
            p.height - padding - 63 * cm / 2,
            p.width - 2 * w_pad,
            65 * cm,
        );

        { // Jump lines
            p.fill(ux.green_medium);
            p.textSize(28);

            const is_jumping = tia_start && tia_data === undefined && tov_data !== undefined && !tov_data.cancelled;
            const has_jumped = !tia_start && tia_data !== undefined && !tia_data.canceled;

            if ((is_jumping || has_jumped) && level !== undefined)
            {
                const LINE_W = 80;
                const LINE_H = 2;

                // TOV
                const tov_y = p.height - Math.min(tov_data.height, 0.60) * 100 * cm - padding;
                p.rect(LINE_W / 2 + 80, tov_y, LINE_W, LINE_H);

                // Text
                p.fill(brand.white);
                p.textAlign(p.RIGHT, p.BOTTOM);
                p.text((tov_data.height * 100).toFixed(1), LINE_W + 80, tov_y - LINE_H);
                p.fill(ux.green_medium);

                if (is_jumping)
                {
                    tia_ish = Math.min(AT.tia.get_current_jump_height(), 0.60);

                    // TIA Ish
                    const tia_y_ish = p.height - tia_ish * 100 * cm - padding;
                    p.rect(p.width - LINE_W / 2 - 80, tia_y_ish, LINE_W, LINE_H);

                    // Text
                    p.fill(brand.white);
                    p.textAlign(p.LEFT, p.BOTTOM);
                    p.text((tia_ish * 100).toFixed(1), p.width - LINE_W - 80, tia_y_ish - LINE_H);
                    p.fill(ux.green_medium);
                }
                else if (has_jumped)
                {
                    // // TIA Ish
                    // const tia_y_ish = p.height - tia_ish * 100 * cm - padding;
                    // p.rect(p.width - 50, tia_y_ish, 100, 5);

                    // TIA Actual
                    const tia_y_actual = p.height - Math.min(tia_data.height, 0.60) * 100 * cm - padding;
                    p.rect(p.width - LINE_W / 2 - 80, tia_y_actual, LINE_W, LINE_H);

                    // Text
                    p.fill(brand.white);
                    p.textAlign(p.LEFT, p.BOTTOM);
                    p.text((tia_data.height * 100).toFixed(1), p.width - LINE_W - 80, tia_y_actual - LINE_H);
                    p.fill(ux.green_medium);
                }
            }
        }

        // { // Graph
        //     const GRAPH_HEIGHT = 20 * cm;
        //     const MAX_W = 420;

        //     p.fill(brand.black_lite);
        //     p.rectMode(p.CORNER);
        //     p.rect(0, p.height - GRAPH_HEIGHT - 2 * cm, p.width, GRAPH_HEIGHT + 2 * cm);

        //     p.strokeWeight(4);
        //     p.stroke(brand.gold);
        //     p.noFill();
        //     p.beginShape();

        //     for (let i = 0; i < (points.length - 1); ++i) {
        //         const curr = points[i];
        //         const next = points[i + 1];

        //         p.vertex(
        //             2 * cm + i * ((p.width - 4 * cm) / (points.length - 1)),
        //             p.height - 1 * cm - Math.min((curr / MAX_W), 1) * GRAPH_HEIGHT,
        //         );
        //         p.vertex(
        //             2 * cm + (i + 1) * ((p.width - 4 * cm) / (points.length - 1)),
        //             p.height - 1 * cm - Math.min((next / MAX_W), 1) * GRAPH_HEIGHT,
        //         );
        //     }
        //     p.endShape();
        // }
    };

    p.windowResized = () => {
        p.resizeCanvas(p.windowWidth, p.windowHeight);
    };
};

new p5(sketch);
