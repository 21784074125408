
import React, { useContext, useEffect, useState } from "react";
import ScreenContext, { Screen } from "../../../helpers/ScreenContext";
import * as AT from "../../../init";
import styles from "./CMJ.less";
import { Events as TOVEvents } from "@alphatekas/pwr_core/dist/cmj/tov";
import { Events as TIAEvents } from "@alphatekas/pwr_core/dist/cmj/tia";

type Report = {
    tov: TOVEvents["end"];
    tia: TIAEvents["end"];
};

type Props = {};

const CMJ = (props: Props): JSX.Element => {
    const [screen, setScreen] = useContext(ScreenContext);

    const [tov, setTov] = useState<TOVEvents["end"]>();
    const [tiaStart, setTiaStart] = useState<TIAEvents["start"]>();
    const [tiaEnd, setTiaEnd] = useState<TIAEvents["end"]>();

    const [report, setReport] = useState<Report[]>();

    // If levels change at this point, user is done
    useEffect(() => {
        AT.leveller.once("level", () => {

            // Send to "weigh-in" on activity
            setTimeout(() => {
                AT.forceplate.once(
                    "active",
                    () => setScreen(Screen.WeighIn)
                );
            }, 1000);
        });
    }, []);

    const onTov = (data: TOVEvents["end"]) => {
        setTov(data);
    };

    const onTiaStart = (data: TIAEvents["start"]) => {
        setTiaStart(data);
    };

    const onTiaEnd = (data: TIAEvents["end"]) => {
        setTiaEnd(data);
    };

    useEffect(() => {
        AT.tov.on("end", onTov);
        AT.tia.on("start", onTiaStart);
        AT.tia.on("end", onTiaEnd);

        return () => {
            AT.tov.off("end", onTov);
            AT.tia.off("start", onTiaStart);
            AT.tia.off("end", onTiaEnd);
        };
    }, []);

    const addReport = (report: Report) => {
        setReport(ps =>
            ps === undefined
                ? [report]
                : [...ps, report]
        );
    }

    useEffect(() => {
        if (tiaEnd !== undefined && tiaEnd.canceled === false)
        {
            addReport({ tov, tia: tiaEnd });
        }
    }, [tiaEnd]);

    return (
        <div className={styles.CMJ}>

            {report && (
                <div className={styles.Report}>
                    <div className={styles.Top}>
                        <div>
                            <div>TOV</div>
                            <div>TIA</div>
                            <div>Average</div>
                        </div>
                    </div>
                    <div className={styles.Bottom}>
                        {/* <div>
                            <div>
                                <div>Height</div>
                                <div>XX.X<span>cm</span></div>
                                <div>XX.X<span>cm</span></div>
                                <div>XX.X.toFixed(1)}<span>cm</span></div>
                            </div>
                            <div>
                                <div>Velocity</div>
                                <div>XX.X<span>m/s</span></div>
                                <div>XX.X<span>m/s</span></div>
                                <div>XX.X<span>m/s</span></div>
                            </div>
                        </div> */}
                        {(report || []).map(_ => (
                            <div>
                                <div>
                                    <div>Height</div>
                                    <div>{(_.tov.height * 100).toFixed(1)}<span>cm</span></div>
                                    <div>{(_.tia.height * 100).toFixed(1)}<span>cm</span></div>
                                    <div>{((_.tov.height + _.tia.height) * 100 / 2).toFixed(1)}<span>cm</span></div>
                                </div>
                                <div>
                                    <div>Velocity</div>
                                    <div>{_.tov.tov.toFixed(2)}<span>m/s</span></div>
                                    <div>{_.tia.tov.toFixed(2)}<span>m/s</span></div>
                                    <div>{((_.tov.tov + _.tia.tov) / 2).toFixed(2)}<span>m/s</span></div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

type InstructionProps = {
    active: boolean;
    step: number;
    text: {
        header: string;
        description: string;
        // iconText: string;
    }
    svg: string;
    progress?: number;
};

export default CMJ;

export {
    CMJ,
    Props,
};
